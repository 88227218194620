/**
 * import and init global plugins
 */
import Vue from 'vue'

import '~/plugins'
import './router-sync'
import './svg-icon'
import './charts'

// @ts-ignore
import VueMoment from 'vue-moment'
Vue.use(VueMoment)
