






































import { Component, Vue } from 'vue-property-decorator'
import { UploadType } from '~/store/upload'
import { AuthStore } from '~/store/auth'
import { UserStore } from '@admin/store/user'
import { ENV_IS_DEVELOPMENT, ENV_IS_STAGING } from '~/const/environment'

@Component
export default class Navbar extends Vue {
	UploadType = UploadType
	isSearch = false
	showMobileMenu = false

	@UserStore.State('authenticatedUser') authenticatedUser: IUser
	@AuthStore.Getter('isAuthenticated') isAuthenticated: boolean

	@AuthStore.Action('logout') logout: () => void
	@UserStore.Action('resetAuthenticatedUser') resetAuthenticatedUser: () => void

	finalLogout() {
		this.resetAuthenticatedUser()
		this.logout()
	}

	toggleMobileMenu(itemToToggle: any) {
		// if client width is less then 1000 (tablet on mobile TODO test tablet)
		if (this.$el.clientWidth <= 1000) {
			this.showMobileMenu = !this.showMobileMenu
			// add remove class
			// if (this.showMobileMenu) document.body.classList.add('noScroll')
			// else document.body.classList.remove('noScroll')
			// open / close search & block body scroll if the search is open
			if (itemToToggle === 'search' && this.showMobileMenu === true) this.isSearch = true
			else if (this.isSearch === true) this.isSearch = false
		}
	}
}
