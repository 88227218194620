





































import Vue from 'vue'
import { Component, Mixins } from 'vue-property-decorator'
import { Howl } from 'howler'
import { PlayerStore, EVENT_LOAD_TRACK, EVENT_AUDIO_TOGGLE } from '~/store/player'
import ThePlayerLoader from '@/components/ThePlayerLoader.vue'
import { AbstractPlayer } from '~/mixins/abstract-player'
import { BASE_URL } from '~/const/base-url'
import { mixin } from 'vue/types/umd'

@Component({ components: { ThePlayerLoader } })
export default class ThePlayer extends Mixins(AbstractPlayer) {
}
