import { namespace } from 'vuex-class'
import { fetchOwnProfile } from '~/api/user'
import { VuexActionData } from '~/interfaces/interface-utils'

export const UserStore = namespace('user')

interface State {
	authenticatedUser: IUser
}

export default {
	namespaced: true,
	state: {
		authenticatedUser: null
	} as State,
	mutations: {
		SET_USER_INFO: (state: State, payload: IUser) => (state.authenticatedUser = payload)
	},
	actions: {
		async fetchAdminInfo({ commit, dispatch }: VuexActionData<State>) {
			commit('modal/SET_LOADING_STATE', true, { root: true })
			const user = await fetchOwnProfile()
			commit('modal/SET_LOADING_STATE', false, { root: true })
			// logout if not an admin
			if (user?.is_admin) {
				commit('SET_USER_INFO', user)
			} else {
				await dispatch('auth/logout', { redirectToLogin: true }, { root: true })
				console.error('User is not an admin')
			}
		},
		resetAuthenticatedUser({ commit }: VuexActionData<State>) {
			commit('SET_USER_INFO', null)
		}
	}
}
