import Vue from 'vue'
import { Store } from 'vuex'
import { Route } from 'vue-router'

declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $axios: any
        router: any
        $route: Route
        moment: any
        $store: Store<any>
    }
    interface Vue {
        $axios: any
        router: any
        $route: Route
        moment: any
        $store: Store<any>
    }
}

import App from '@admin/App.vue'
import router from '@admin/router'
import store from '@admin/store'
import '@admin/plugins'
import '~/directives'

import '#/sass/main.sass'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h: any) => h(App)
}).$mount('#app')
