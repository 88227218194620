import Sidebar from '@admin/layout/Sidebar.vue'
import Navbar from '@admin/layout/Navbar.vue'
import { castParam } from '~/services/router-helper'

export default [
	{
		path: '*',
		redirect: '/'
	},
	{
		path: '/',
		name: 'Home',
		redirect: { name: 'Dashboard' }
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		components: {
			default: () => import('@admin/views/Dashboard.vue'),
			sidebar: Sidebar,
			navbar: Navbar
		},
		meta: { requiresAuth: true }
	},
	{
		path: '/payouts',
		name: 'Payouts',
		components: {
			default: () => import('@admin/views/Payouts.vue'),
			sidebar: Sidebar,
			navbar: Navbar
		},
		meta: { requiresAuth: true }
	},
	{
		path: '/new-uploads/:pageIndex',
		name: 'NewUploads',
		components: {
			default: () => import('@admin/views/NewUploads.vue'),
			sidebar: Sidebar,
			navbar: Navbar
		},
		props: { default: castParam },
		meta: { requiresAuth: true }
	},
	{
		path: '/login',
		name: 'Login',
		components: { default: () => import('@admin/views/Login.vue') },
		meta: { guest: true }
	}
]
