import Vue from 'vue'
import Vuex from 'vuex'

// modules
import base from '@admin/store/base'
import auth from '~/store/auth'
import user from '@admin/store/user'
import modal from '~/store/modal'
import player from '~/store/player'
// needed for the player
import cart from '~/store/cart'

// caches
import { vuexToken } from '~/store/vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [vuexToken],
	modules: { base, auth, user, modal, player, cart }
})
