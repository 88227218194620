import Vue from 'vue'

import { namespace } from 'vuex-class'
import { VuexActionData } from '~/interfaces/interface-utils'

export const BaseStore = namespace('base')

export default {
	namespaced: true,
	actions: {
		async onAppLoad({ rootState, dispatch }: VuexActionData<{}>) {
			Vue.$axios.defaults.headers.common.Authorization = `Token ${rootState.auth.token_id}`
			await dispatch('user/fetchAdminInfo', null, { root: true })
		}
	}
}
