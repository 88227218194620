import Vue from 'vue'
import Router from 'vue-router'

import { redirect } from '@admin/router/middlewares'
import routes from '@admin/router/routes'
import { scrollBehavior } from '~/utils/scrollBehaviour'

Vue.use(Router)

const router = new Router({ mode: 'history', routes, scrollBehavior })

router.beforeEach(redirect)

Vue.router = router
Object.defineProperty(Vue.prototype, 'router', { get: () => router })

export default router
